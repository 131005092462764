html, .Toastify__toast-body, select, input, button, textarea{ 
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
}
body{
  color: #111C2B;
}

@media screen and (max-width: 600px) {
  h1{
    font-size: 7vw;
  }
  h2{
    font-size: 6vw;
  }
  h3{
    font-size: 5vw;
  }
  p, body{
    font-size: 4vw;
  }
}

/* textes en rouge */
.warning{
  color : #eb5a46;
}