/* end check */
.end-check svg{
  margin: auto;
  font-size: 120px;
  margin-bottom: 22px;
}

/* faq */
.faq-header{
  margin-bottom: 42px;
}
.faq-container{
  width: 100%;    
  height: calc(100vh - 80px);
  margin: 40px;
}
.faq-section-header{
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 22px;
}
.faq-section-open{
  margin-bottom: 62px;
}
.faq-section-open img{
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  margin: 20px 0px;
}
.faq-section-header:hover{
  background-color: #dedede;
}
.faq-section-header svg{
  margin: auto 12px;
  font-size: 22px;
}

/* intro */
.intro-language{
  margin-right: 8px;
  font-size: 1.5em;
}
.intro-text{
  width: 300px;
  margin: 40px;
}
.intro-text-alternative{
  width: 600px;
}
.intro-image{
  margin: 20px;
}
.intro-image img{
  border-radius: 8px;
  max-width: 260px;
  height: auto;
}
.intro-image-alternative img{
  max-width: 100px;
}

/* login */
.login-message{
  text-align: center;
}
.login-message svg{
  font-size: 32px;
  margin-top: 12px;
}

/* page message */
.message{
  margin: 40px;
}
.message textarea{
  width: 100%;
  border: none;
  resize: none;
  height: 132px;
  border-radius: 8px;
  font-size: initial;
}

/* page next */
.next-axis-text{
  width: 360px;
  margin: 30px;
}
.next-axis-header{
  text-align: center;
  width: 320px;
}
.next-axis-image img{
  height: 200px;
  width: 200px;
  margin: 20px auto;
}

/* questions */
.questions-infos{
  color: white;
  padding: 20px 40px 12px 40px;
}
.questions-infos svg{
  margin: 4px 12px 0px 0px;
}
.questions-back svg{
  margin: auto 30px;
  color: white;
  font-size: 32px;
}
.questions-container{
  position: relative;
  min-width: 300px;
  margin: 12px;
  height: 500px;
  background-color: #ffffff4f;
  border-radius: 8px;
}
.questions-secondary-background{
  background-color: #0000007d;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 1;
}

/* affichage conditionnel mobile */
@media screen and (max-width: 600px) {
  .intro-image{
    display: none;
  }
  .gender-image{
    display: none;
  }
  .question{
    height: calc(100vh - 160px)
  }
  .questions-container{
    width: 100%;
    height: calc(100vh - 160px)
  }
  .next-axis-header{
    width: initial;
  }
  .next-axis-image img{
    width: 75%;
    height: 75%;
  }
}