.flex{ 
  display: flex; 
  display: -webkit-box; 
  display: -webkit-flex;
}
.flex1{ flex: 1; }
.flex2{ flex: 2; }
.flex3{ flex: 3; }
.flex4{ flex: 4; }
.flex-wrap{ flex-wrap: wrap; }
.flex-dcol{ 
  flex-direction: column;
  -webkit-flex-direction: column;
}
.flex-jcenter{ justify-content: center; }
.flex-jsa{ justify-content: space-around; }
.flex-jsb{ justify-content: space-between; }
.flex-ace{ align-items: center; }