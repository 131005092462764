
/* keyframes */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* hover */
._hover{
  cursor: pointer;
}
._hover:hover{
  opacity: 0.8;
}

.axis{
  width: 132px;
  border-radius: 8px;
  margin: 12px;
  background-color: white;
}
.axis-image img{
  height: 100px;
  width: 100px;
  border-radius: 8px;
  margin: 10px auto;
}
.axis-image-account{
  width: 80px !important;
  height: 80px !important;
  margin: 20px auto !important;
}
.axis-text{
  color: white;
  height: 60px;
  text-align: center;
  padding: 12px;
}
.axis-text span{
  margin: auto;
}
.axis-icon{
  width: 100%;
  height: 120px;
}
.axis-icon svg{
  margin: auto;
  font-size: 48px;
}

.button{
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  border-radius: 8px;
  padding: 12px 20px;
  margin: 12px 0px;
}
.card{
  background-color: white;
  padding: 20px 30px;
  margin: 40px;
  border-radius: 8px;
}
.card-sm{
  width: 100%;
}

.card-button{
  border: 1px solid;
  border-radius: 8px;
  margin: 8px;
  padding: 8px;
  width: 120px;
  height: 120px;
  font-weight: bold;
  font-size: 14px;
}
.card-button svg{
  margin-bottom: 6px;
}

/* checkbox */
.checkbox-container{
  height: 36px;
  margin: auto;
}
.checkbox-inactive{
  opacity: 0.5;
}
.checkbox-inactive .checkbox{
  cursor: initial;
}
.checkbox{
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  margin: auto;
  border-radius: 8px;
  border: 2px solid;
  border-color: #8C8C8C;
  background-color: white;
}
.checkbox-action .button{
  margin: -2px;
  margin-bottom: -4px;
}
.checkbox svg{
  color: white;
  margin: auto;
}
.checkbox-active{
  border-color: #111C2B;
  background-color: #111C2B;
}
.checkbox-label{
  margin: auto 12px
}

.optional-modal{
  margin: auto;
  background-color: white;
  min-width: 400px;
  max-width: 400px;
  border-radius: 8px;
  color: initial;
  padding: 10px 30px;
}
.optional-modal select{
  width: 200px;
  height: 38px;
  margin: auto;
}
.optional-modal-title{
  text-align: center;
  padding: 20px;
}
.optional-modal-container{
  margin: 0px -30px;
}
.optional-modal-line{
  border-top: 1px solid #d2d2d2;
  padding: 0px 30px;
}
.optional-modal-line p{
  margin: 14px 0px;
}

@media screen and (max-height: 588px) {
  .question{
    height : calc(100vh - 88px) !important
  }
  .questions-container{
    height : calc(100vh - 88px) !important
  }
}

.question{
  background-color: white;
  border-radius: 8px;
  height: 500px;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.question-draft{
  position: absolute;
  background-color: #eb5a46; 
  color: white;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  padding: 10px;
  margin-top: -62px;
}
.question-draft-safari{
  position: initial !important;
  margin-top: 0px;
}
.question-image{
  color : #d7d7d7;
}
.question-fullscreen .question-image svg{
  display: none;
}
.question-image svg{
  font-size: 32px;
  margin: 12px;
}
.question-count{
  color: #a7a7a7;
  margin: 0px 30px;
  margin-top: 20px;
}
.question-fullscreen{
  position: fixed;
  width: calc(100vw - 60px);
  height: calc(100vh - 60px);
  top: 30px;
  left: 30px;
  z-index: 3;
}
.question-header img{
  width: 60px;
  height: 60px;
  margin: 8px;
}
.question-header-chevron svg{
  margin: auto;
}
.question-header-border{
  border-bottom: 2px solid #d7d7d7;
  width: 20px;
  height: 50%;
  min-height: 22px;
}
.question-header-fullscreen img{
  width: 140px;
  height: 140px;
  margin: 20px;
}
.question-header-fullscreen .question-header-border{
  border-bottom : none;
}
.question-secondary{
  color: #a7a7a7;
  margin: 12px;
}
.question-topic{
  padding: 0px 10px;
  color: #a7a7a7;
}
.question-topic-fullscreen{
  padding: 0px 30px;
  margin-top: 6px;
}
.question-name{
  text-align: center;
  padding: 0px 8px;
  font-weight: 600;
}
.question-name a{
  color: #7e7e7e;
}
.question-name span{
  margin: auto;
  overflow-wrap: anywhere;
  font-weight: normal;
}
.question-name-big{
  font-size: 1.5em;
  padding: 0px 20px;
}
.question-name-choice{
  font-size: 12px;
  margin-top: -12px;
  margin-bottom : 6px
}
.question-name-choice span{
  font-style: italic;
}
.question-name-fullscreen{
  text-align: left;
  font-size: 22px;
  padding: 0px 30px;
}
@media screen and (max-width: 600px) {
  .question-name-big{
    font-size: 6vw;
  }
}
.question-propositions{
  overflow-y: auto;
  width: 100%;
}
.question-proposition{
  margin: 8px 12px;
  font-size: 12px;
}
.question-propositions-sm .question-proposition{
  margin: 2px 12px;
  font-size: 12px;
}
.question-proposition span{
  margin: auto 0px;
}
.question-proposition-circle{
  width: 26px;
  height: 26px;
  border: 2px solid;
  border-radius: 50%;
}
.question-propositions-sm .question-proposition-circle{
  width: 22px;
  height: 22px;
}
.question-proposition-circle-center{
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  border-radius: 50%;
  margin: 2px;
  background-color: #5c5c5c;
}
.question-proposition-order{
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.question-proposition-circle-choice{
  margin: auto;
  margin-right: 12px;
}
.question-boolean-container{
  margin: 0px 30px 12px 30px;
}
.question-boolean{
  border: 2px solid;
  margin: auto;
  border-radius: 50%;
}
.question-boolean-center{
  text-align: center;
  color : white;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 4px;
}
.question-boolean-center span{
  margin: auto;
}
.question-likert-label-container{
  height: 54px;
}
.question-likert{
  position: relative;
  min-width: 36px;
}
.question-likert-gradient{
  height: 10px;
}
.question-likert-circle{
  position: absolute;
  width: 28px;
  height: 28px;
  right: -14px;
  top: -9px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}
.question-likert-circle:hover, .question-likert-circle-done{
  width: 36px;
  height: 36px;
  right: -18px;
  top: -14px;
}
.question-likert-label{
  text-align: center;
  margin-top: 18px;
  font-size: 12px;
}
.question-button{
  margin: 10px 30px;
}
.question-nspp{
  font-size: 12px;
  font-weight: 400; 
  text-align: center;
  margin: 16px 0;
}
.question-nspp-text{
  margin: auto 10px
}
@media screen and (max-width: 600px) {
  .question{
    height: 100%
  }
  .question-likert-label, .question-proposition{
    font-size: 3.5vw;
  }
}
.question-done{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  opacity: 0.7;
}
.question-done:hover{
  opacity: 0.6;
}
.question-open{
  margin: 20px;
}
.question-open textarea{
  margin: 12px;
  resize: none;
  height: 100px;
  font-size: 14px;
  border: none;
  padding: 4px 8px;
  background-color: #eaeaea;
  border-radius: 8px;
  width: calc(100% - 40px);
}
.question-open-help{
  margin: 0px 20px;
  margin-bottom: -20px;
  margin-top: -20px;
}
.question-open-help p{
  font-size: 12px;
}
.question-nps{
  border-radius: 50%;
  height: 26px;
  width: 26px;
  color: white;
}
.question-nps-active{
  height: 40px;
  width: 40px;
  margin: -8px;
}
.question-nps-container{
  height: 70px;
}
.question-nps span{
  margin: auto;
}
.question-nps-label{
  color: #6a6a6a;
  font-size: 12px;
  margin: -8px 20px 14px 20px;
}
.question-nps-separator{
  border-bottom: 1px solid;
  margin-bottom: 8px;
  margin-left: 12px;
}
.question-nps-chevron{
  margin: 3px 8px 0px -5px;
  font-size: 11px;
}

.page{
  background-color: #ebeff2;
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow-y: auto;
  -webkit-overflow-scrolling: "touch";
}
.page-footer{
  text-align: center;
  padding: 40px 80px;
}
.save-modal{
  margin: auto;
  background-color: white;
  min-width: 380px;
  min-height: 160px;
  border-radius: 8px;
  text-align: center;
  color: #5c5c5c;
}
.save-modal svg{
  font-size: 42px;
  margin-top: 20px;
}
.save-modal-container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #00000059;
  top: 0px;
  left: 0px;
  z-index: 99;
}

.scale-bar{
  width: 90%;
  height: 4px;
  background-color: #e9e9e9;
  position: absolute;
  top: 8px;
  margin: 10px
}

.scale-circle{
  width: 30px;
  height: 30px;
  background-color:#c9c9c9;
  border-radius: 20px;
  font-size: 20px;
  z-index: 1
}

.scale-circle-2{
  width: 50px;
  height: 50px;
  border-radius: 50px
}

.scale-circle-5{
  margin: 5px;
}

.scale-circle-10{
  margin: -5px;
}

.selected{
  box-shadow: inset 3px -3px 0px 0px rgba(17,28,43,1), inset -3px 3px 0px 0px rgba(17,28,43,1);
}

.selector{
  border: 1px solid;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  margin: 22px 2px;
  position: relative;
}
.selector-values{
  position: absolute;
  background-color: white;
  border-radius: 8px;
  padding: 20px 0px;
  border: 1px solid;
  width: 100%;
  top: 42px;
  left: 0px;
  z-index: 1;
}
.selector-values-scrollable{
  max-height: 242px;
  padding: 0px 20px;
  overflow-y: auto;
}
.selector-value{
  padding: 4px;
}
.topic-title{
  margin: 0 5px;
  padding:0 5px;
  font-size: 12px
}

.textarea-message{
  border: 2px solid #c9c9c9;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  height: 100px;
}

/* text input */
.text-input{
  padding: 10px 0;
  cursor: pointer;
  margin-top: 10px;
  border: none;
}
.text-input-error input{
  border-color: #eb5a46 !important;
}
.text-input input, .text-input{
  min-width: 130px;
  min-height: 22px;
  border-radius: 8px;
  stroke-width: 1;
  font-size: 14px;
  margin: 4px 0px;
}
.text-input input{
  width: 340px;
  padding: 10px 14px;
  border: 1px solid #b8b8b8;
}
.text-input input:focus{
  outline: none;
}
.text-input-check{
  margin: auto;
  font-size : 18px;
  margin-left: 8px;
  color: #eb5a46
}
.text-input-title{
  margin-bottom: 6px;
  margin-top: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-input input:disabled {
  background-color: white;
  border: none;
  color : #8c8c8c;
}

.warning-modal-container{
  position: absolute;
  background-color: #5c5c5c5e;
  border-radius: 8px;
  height: 100%;
  width: 100%;
}
.warning-modal{
  margin: auto 20px;
  background-color: white;
  border-radius: 8px;
  padding: 10px 16px 0px 16px;
  text-align: center;
  width: 100%;
}